@import 'src/shared/style/colors.scss';

.note-navigation {
  min-height: 100%;
  @include themify($themes) {
    background-color: themed('colorSecondary');
  }

  .note-navigation-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include themify($themes) {
      border-bottom: 1px solid themed('colorSecondaryLight');
    }

    h1 {
      color: $color-white;
      font-size: 26px;
      line-height: 1.4;
      text-transform: uppercase;
    }

    button {
      font-size: 24px;
    }
  }

  .note-navigation-list {
    padding: 20px 0;

    &-item {
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
      transition: background-color 0.2s linear;

      &:hover,
      &.active {
        background-color: $color-menu-active;
      }

      .ant-list-item-meta-title {
        color: $color-white;
      }

      .ant-list-item-meta-description {
        color: $color-border;
      }
    }

    .note-avatar {
      border-radius: 4px;
      @include themify($themes) {
        background-color: themed('colorTitle');
      }
    }
  }
}
