@import 'src/shared/style/colors.scss';

.industry-search {
  display: flex;

  .industry-search-intro {
    width: 45%;
    max-width: 500px;
    padding: 40px;

    h1 {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 1.4;
      text-transform: uppercase;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.1;
      margin-top: 48px;

      @include themify($themes) {
        color: themed('colorSecondary');
      }

      > span {
        @include themify($themes) {
          color: themed('colorPrimary');
        }
      }
    }
  }

  .industry-search-description {
    max-height: 150px;
    overflow-y: auto;
  }

  .industry-search-area {
    width: 55%;
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include themify($themes) {
      background-image: linear-gradient(themed('colorGradient1'), themed('colorGradient2'));
    }

    h1 {
      font-weight: 700;
      @include themify($themes) {
        color: themed('colorGradientTitle');
      }
    }

    p {
      margin-bottom: 36px;
      @include themify($themes) {
        color: themed('colorGradientTitle');
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .industry-search {
    .industry-search-intro {
      display: none;
    }

    .industry-search-area {
      width: 100%;
    }
  }
}
