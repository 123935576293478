$color-width: 36px;
$color-width-sm: 30px;

.report-theme-select {
  display: flex;
  align-items: center;

  &-option {
    display: flex;
  }

  &-colors {
    margin-right: 1px;
    width: $color-width;
    height: $color-width;
  }

  .ant-select {
    margin-right: 10px;

    .ant-select-selection__rendered {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1024px) {
  .report-theme-select {
    &-colors {
      width: $color-width-sm;
      height: $color-width-sm;
    }
  }
}
