@import 'src/shared/style/colors.scss';

.report-preview-cover {
  padding-top: 72px;

  h1,
  h2 {
    text-transform: uppercase;
  }

  h1 {
    font-size: 36px;
    line-height: 1.4;
  }

  h2 {
    font-size: 24px;
    line-height: 1.6;
  }

  .report-preview-cover-header {
    margin-left: 72px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin-right: 40px;
    }

    hr {
      height: 2px;
      margin: 0;
      flex: 1;
      border: none;
    }

    .company-logo {
      width: 180px;
      height: 60px;
      margin-right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      background-color: $color-grey;
      font-size: 28px;
    }
  }

  .report-preview-cover-banner {
    position: relative;
    height: 266px;
    background-image: url(./assets/banner.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
      position: absolute;
      left: 72px;
      bottom: 0;
      font-weight: 700;
    }
  }

  .report-preview-cover-center {
    position: relative;
    height: 480px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(./assets/cover.png);

    h1,
    h2 {
      position: absolute;
      color: $color-white;
    }

    h1 {
      right: 72px;
      bottom: 60px;
      text-align: right;
    }

    h2 {
      left: 72px;
      top: 10px;
    }
  }

  .report-preview-cover-footer {
    padding: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
