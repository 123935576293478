@import 'src/shared/style/colors.scss';

@media print {
  h1,
  h2,
  thead,
  th {
    page-break-after: avoid;
  }

  h1,
  h2,
  p,
  thead,
  th,
  tr,
  td {
    page-break-inside: avoid;
  }
}
