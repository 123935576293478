.course-browse {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  > p {
    text-align: center;
  }

  .course-browse-list {
    margin-top: 40px;

    .powered-by {
      max-width: 120px;
    }
  }
}
