@import 'src/shared/style/colors.scss';

.dashboard-ad {
  position: relative;

  &-cover {
    height: 480px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 20px;
    padding: 20px;
    background-color: rgba($color-white, 0.8);
    text-align: center;

    h1 {
      margin-bottom: 12px;
      font-weight: 700;
      line-height: 1.2;
    }

    h3 {
      margin-bottom: 24px;
    }
  }
}
