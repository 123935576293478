@import 'src/shared/style/themes/blue-yellow.scss';

.hazard-index-item-container {
  border-radius: 4px;

  &.is-dragging {
    box-shadow: 0px 3px 6px rgba(99, 117, 136, 0.16), 0px 3px 6px rgba(99, 117, 136, 0.23);

    .hazard-index-item {
      border-color: lighten($color: $color-secondary, $amount: 5) !important;
      border-style: solid !important;
    }
  }

  // Common styles regardless of appearance
  .hazard-index-item {
    background-color: white;

    &-title {
      font-size: 14px;
      font-weight: 400;

      .hazard-index-item-name {
        margin-left: 8px;
      }
    }

    .tile-comment-button {
      border-radius: 4px;
      color: white;
      border: none;

      &:hover {
        border: none;
      }
    }
  }

  &.appearance-tile {
    margin-bottom: 8px;

    .hazard-index-item {
      border: 1px dashed $color-secondary;
      border-radius: 4px;
      padding: 16px;

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-rating {
        margin: 16px auto;
      }

      &-title {
        margin-bottom: 8px;
      }

      &-edit {
        width: 100%;
        font-size: 16px;
        text-align: right;
      }
    }
  }

  &.appearance-row {
    margin: 8px 0;

    .hazard-index-item {
      margin: 0;
      padding: 8px;
      border: 1px dashed $color-secondary;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-info {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-rating {
        margin-left: 16px;
        margin-right: 16px;
        font-size: 14px;
      }

      &-edit {
        display: flex;
        i {
          margin-left: 8px;
        }
      }
    }
  }
}
