@import 'src/shared/style/colors.scss';

.button {
  @include themify($themes) {
    color: themed('colorPrimary');
    background-color: transparent;
    border-color: themed('colorPrimary');

    &:hover {
      color: themed('colorPrimaryHover');
      border-color: themed('colorPrimaryHover');
    }

    &:active {
      color: themed('colorPrimaryActive');
      border-color: themed('colorPrimaryActive');
    }

    &.primary {
      color: $color-white;
      background-color: themed('colorPrimary');
      border-color: themed('colorPrimary');

      &:hover {
        background-color: themed('colorPrimaryHover');
        border-color: themed('colorPrimaryHover');
      }

      &:active {
        background-color: themed('colorPrimaryActive');
        border-color: themed('colorPrimaryActive');
      }
    }

    &.link {
      border-color: transparent;
    }

    &.secondary {
      color: themed('colorSecondary');
      border-color: themed('colorSecondary');

      &:hover {
        color: themed('colorSecondaryHover');
        border-color: themed('colorSecondaryHover');
      }

      &:active {
        color: themed('colorSecondaryActive');
        border-color: themed('colorSecondaryActive');
      }

      &.primary {
        color: $color-white;
        background-color: themed('colorSecondary');
        border-color: themed('colorSecondary');

        &:hover {
          background-color: themed('colorSecondaryHover');
          border-color: themed('colorSecondaryHover');
        }

        &:active {
          background-color: themed('colorSecondaryActive');
          border-color: themed('colorSecondaryActive');
        }
      }

      &.link {
        border-color: transparent;
      }
    }
  }
}
