@import 'src/shared/style/colors.scss';

.dashboard-knowledge-card {
  padding: 40px 40px 20px;
  display: flex;

  &-icon {
    @include themify($themes) {
      background-color: themed('colorSecondary');
    }
  }

  &-info {
    margin-left: 20px;
    flex: 1;

    h2 {
      margin-bottom: 10px;
      line-height: 1.2;
    }

    .ant-typography {
      margin: 0;
    }
  }

  &.single {
    flex-direction: column;
    .dashboard-knowledge-card-info {
      margin-left: 0px;
      margin-top: 30px;
    }
  }
}
