.point {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  &-card-list {
    padding: 20px 0;

    &-item {
      margin-bottom: 40px;
    }
  }

  &-title {
    margin-bottom: 20px;
  }

  &-subtitle {
    margin-bottom: 10px;
  }

  &-period {
    width: 400px;
    max-width: 100%;
    margin: 20px 0;
  }
}
