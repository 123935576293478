@import 'src/shared/style/colors.scss';

.not-found {
  padding: 80px 0;
  text-align: center;

  h1 {
    margin: 30px 0;
    font-size: 4vh;
    font-weight: 400;
    text-transform: uppercase;
  }

  h2 {
    margin-bottom: 0.5em;
    padding: 40px 0;
    display: inline-block;
    font-size: 7vh;
    font-weight: 400;
    border-width: 1px 0;
    border-style: solid;
    @include themify($themes) {
      color: themed('colorPrimary');
      border-color: themed('colorPrimary');
    }
  }

  p {
    margin: 15px 0;
    font-size: 16px;
  }
}
