.report-preview-user-profile {
  margin-bottom: 40px;
  display: flex;

  .ant-avatar {
    margin-right: 40px;
  }

  ul {
    padding: 0;
    margin-top: 20px;
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;

      > i {
        margin-right: 10px;
        padding-top: 3px;
        line-height: 1;
      }
    }
  }
}
