@import 'src/shared/style/colors.scss';

.industry-search-box {
  position: relative;
  width: 500px;
  display: flex;
  margin-left: auto;

  .ant-select-selection {
    padding-left: 32px;
    background-color: transparent;
    @include themify($themes) {
      color: themed('colorGradientText');
      border-color: themed('colorGradientText');
    }
  }

  &-icon {
    position: absolute;
    top: 8px;
    left: 12px;
    font-size: 18px;
    @include themify($themes) {
      color: themed('colorSecondary');
    }

    &.large {
      top: 11px;
      font-size: 22px;
    }
  }

  &-select {
    flex: 1;
    overflow: hidden;
  }

  &-select-dropdown {
    @include themify($themes) {
      background-color: rgba(themed('colorBase'), 0.79);
    }

    .ant-select-dropdown-menu-item {
      color: $color-white;

      &-active,
      &-selected,
      &:hover {
        @include themify($themes) {
          color: themed('colorBase');
          background-color: rgba($color-white, 0.79);
        }
      }
    }
  }

  &-btn {
    margin-left: 12px;
  }

  &.vertical {
    width: 100% !important;
    display: block;

    .industry-search-box-select {
      width: 100%;
      margin-bottom: 20px;
    }

    .industry-search-box-btn {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .industry-search-box {
    width: 440px;
  }
}

@media screen and (max-width: 768px) {
  .industry-search-box {
    width: 60%;
  }
}

@media screen and (max-width: 480px) {
  .industry-search-box {
    width: 100%;
    display: block;

    &-select {
      width: 100%;
      margin-bottom: 12px;
    }

    &-btn {
      width: 100%;
      margin-left: 0;
    }
  }
}
