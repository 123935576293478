@import 'src/shared/style/colors.scss';

.course-detail {
  .course-detail-content {
    padding: 40px;
  }

  .course-detail-title {
    padding: 20px 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    @include themify($themes) {
      border-bottom: 2px solid themed('colorSecondary');
    }
  }

  .course-detail-icon {
    margin-right: 20px;
    font-size: 20px;
    @include themify($themes) {
      color: themed('colorSecondary');
    }
  }

  .course-detail-btns {
    text-align: right;

    > button {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .course-detail {
    .course-detail-content {
      padding: 40px 20px;
    }
  }
}
