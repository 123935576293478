@import 'src/shared/style/colors.scss';

.quiz {
  .powered-by {
    display: flex;
    justify-content: end;
    border-bottom: 1px solid $color-border;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .lmi-college-logo {
      max-width: 160px;
    }
  }

  .quiz-item {
    margin-bottom: 24px;

    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-form-item-label {
      text-align: left;
      line-height: 1.6;
      white-space: normal;
    }

    .quiz-item-options {
      width: 100%;

      .quiz-item-option {
        padding: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        white-space: normal;

        &.answered {
          border: 1px solid $color-success;

          &.incorrect {
            border-color: $color-fail;
          }
        }

        &.correct {
          color: $color-success;
          font-weight: 700;
        }

        .ant-radio {
          margin-right: 10px;
        }
      }
    }

    .quiz-item-comment {
      @include themify($themes) {
        color: themed('colorSecondary');
      }
    }
  }

  .quiz-btn {
    margin: 0 auto;
    display: block;
  }
}
