@import 'src/shared/style/colors.scss';

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  @include themify($themes) {
    background-color: themed('colorTitle');
  }

  &.circle {
    border-radius: 50%;
  }

  &.rounded {
    border-radius: 4px;
  }
}
