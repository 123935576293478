@import 'src/shared/style/colors.scss';

.footer {
  padding: 15px 40px;
  color: $color-white;
  @include themify($themes) {
    background-color: themed('colorFooter');
  }

  &-links {
    > a {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &-copyright {
    font-weight: 300;
    text-align: center;
    padding: 5px;
    opacity: 0.8;

    > .divider {
      margin: 12px 0;
      opacity: 0.1;
    }

    > strong {
      font-weight: 600;
    }
  }

  &-social-links {
    min-width: 92px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > a {
      transition: color 0.2s linear, background-color 0.2s linear;

      @include themify($themes) {
        color: themed('colorFooter');
      }

      &:hover {
        @include themify($themes) {
          color: darken(themed('colorFooter'), 5%);
        }
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 576px) {
  .footer {
    &-links {
      text-align: center;
    }
    &-social-links {
      margin-top: 10px;
      justify-content: center;
    }
  }
}
