@import 'src/shared/style/colors.scss';

.risk-checklist-container {
  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      padding: 0;
    }

    .hazard-index-icon {
      width: 40px;
      height: 40px;
    }

    .info {
      margin-left: 16px;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: 500;

        .name {
          margin-right: 56px;
          font-size: inherit;
          font-weight: inherit;
          text-transform: uppercase;
          color: $color-secondary;
        }
      }

      @media screen and (max-width: 576px) {
        margin-left: 0;
        width: 100%;
        .title {
          width: 100%;
          flex-direction: column;

          .name {
            margin-top: 8px;
            margin-right: 0;
            margin-bottom: 16px;
          }

          a {
            width: 100%;
            button {
              width: 100%;
            }
            span {
              width: 100%;
              word-break: normal;
              width: auto;
              display: block;
              white-space: pre-wrap;
              word-wrap: break-word;
              overflow: hidden;
            }
          }

          .rating {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .content {
    padding: 0 20px;
    min-height: 20vh;
    max-height: 55vh;
    overflow-y: scroll;

    @media screen and (max-width: 576px) {
      padding: 0;
    }

    .check-list-item {
      position: relative;
      margin-top: 15px;
      margin-left: 35px;
      overflow: visible;
      color: colorPalette($color-grey, 7);

      &.selected {
        color: inherit;
      }

      .check-box {
        position: absolute;
        left: -35px;
      }

      .add-answer {
        text-transform: uppercase;
        color: $color-primary;
        padding: 0;
      }
    }

    .check-box {
      margin-right: 20px;
    }
  }

  .underwriter-comment-button {
    margin: 20px;
    background-color: colorPalette($color-secondary, 1);
  }
}
