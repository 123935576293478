@import 'src/shared/style/colors.scss';

h1 {
  &.report-preview-heading {
    padding: 10px 30px;
    margin-bottom: 40px;
    display: flex;
    text-transform: uppercase;

    > span {
      min-width: 60px;
    }

    @media print {
      padding: 10px 20px;
    }
  }
}

h2 {
  &.report-preview-heading {
    margin: 40px 0 20px 0;
    display: flex;

    > span {
      min-width: 60px;
    }
  }
}

.report-preview-heading {
  page-break-after: avoid;
  page-break-inside: avoid;
}

.report-preview-no-break {
  page-break-inside: avoid;
}

.report-preview-content {
  margin: 0 80px 80px;
  word-wrap: break-word;
  padding: 0 10px;

  @media print {
    padding: 0;
  }

  &.print-gap-medium {
    @media print {
      margin: 0 80px 60px;
    }
  }
  &.print-gap-small {
    @media print {
      margin: 0 80px 20px;
    }
  }
}

.report-preview-figure {
  > img {
    display: block;
    max-height: 600px;
  }

  figcaption {
    text-align: center;
    font-style: italic;
  }
}

.report-preview-table {
  width: 100%;

  img {
    max-width: 100%;
  }

  thead {
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
    display: table-row-group;
    page-break-after: avoid;
  }

  tbody {
    @media print {
      font-size: 14px;
    }
  }

  th,
  td {
    padding: 10px;
    line-height: 1.5;

    ul {
      margin-inline-end: 15px;
      padding-inline-start: 30px;
    }
  }

  th {
    font-size: 17px;
    line-height: 1.4;
    @media print {
      font-size: 16px;
    }
  }

  &-title {
    text-transform: uppercase;
    border-bottom: 1px solid $color-grey;
  }

  td.report-preview-table-subtitle {
    @extend .report-preview-table-title;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 14px 10px 10px;
  }

  tr.advisor-comment-row {
    @media print {
      page-break-inside: auto;
    }

    td.advisor-comment {
      background: rgba(0, 0, 0, 0.08);
      padding: 14px 18px 20px;
    }

    ol {
      margin-inline-end: 0px;
      padding-inline-start: 0px;

      li.risk-checklist {
        padding-bottom: 15px;
        .risk-checklist-question {
          padding-bottom: 8px;
        }
        .risk-checklist-answers {
          min-height: 70px;
          padding: 14px 18px;
          background: rgba(0, 0, 0, 0.08);
          p {
            margin: 0;
          }
        }
      }
    }
  }

  &-rating {
    width: 140px;
    font-size: 32px;
    text-align: center;
    background-color: #fff;
  }

  &-list {
    page-break-inside: auto;
    td {
      padding-bottom: 0;
      page-break-inside: auto;
      ul {
        margin-bottom: 0;
      }

      p {
        page-break-inside: auto;
      }
    }
  }

  #insurable-gross-profit {
    table {
      margin-bottom: 0;
      margin-top: -20px;
      th,
      td {
        padding: 10px;
      }
    }
  }
}

.report-preview-list {
  &.ordered {
    li {
      margin-bottom: 60px;
    }
  }
}

.report-indicative-rate {
  text-align: center;
  margin: 30px auto;

  .indicative-rate {
    font-weight: 500;
    margin-top: 20px;
  }

  .indicative-rate-apply {
    margin-top: 20px;

    .how-to-apply {
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}
