.underwriter-main {
  padding: 40px;

  h3 {
    margin-bottom: 24px;
  }

  .underwriter-description {
    margin-bottom: 32px;
  }

  .hazard-index-container {
    display: flex;
    flex-wrap: wrap;

    .hazard-index-item-container {
      width: 240px;
      height: 240px;
      padding: 16px 10px;
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid #019cae;
      margin: 0 18px 18px 0;
      position: relative;
      transition: all 0.1s ease-in;

      .icon-container {
        display: none;
        position: absolute;
        right: 8px;
        top: 8px;
      }

      .hazard-index-item {
        &-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &-rating {
          margin: 16px auto;
        }

        &-title {
          height: 40px;
          margin-bottom: 8px;
        }

        &-edit {
          width: 100%;
          font-size: 16px;
          text-align: right;
        }
      }

      &:hover {
        border-width: 3px;
      }
    }
  }

  .hazard-index-item-container.selected {
    border: 3px solid #019cae;

    .icon-container {
      display: block;
    }
  }
}
