@import 'src/shared/style/colors.scss';

.aon-report-preview-cover {
  display: flex;
  flex-direction: row;
  border-left: 40px solid #eb0017;
  height: 100%;

  &-left {
    width: 220px;
    padding: 30px;
    background-color: #e5eff0;
    .company-logo {
      width: 110px;
      height: 100px;
      background-image: url(./assets/aon-logo.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &-right {
    width: 450px;
    height: 670px;
    padding: 250px 0px 30px 30px;
    align-item: center;

    .aon-report-title {
      color: $color-white;
    }

    h1 {
      font-weight: 700;
      font-size: 60px;
    }

    h2.aon-report-title {
      font-size: 20px;
    }

    h2.insured-business-name {
      font-size: 26px;
      margin-top: 50px;
      font-weight: 700;
      font-size: 30px;
      color: #009de0;
    }

    .date-issued {
      margin-top: 60px;
      font-size: 16px;
    }
  }

  .powered-by-container {
    height: 70px;
    width: 180px;
    margin-top: 350px;
    margin-left: -14px;

    > img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}
