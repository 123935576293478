@import 'src/shared/style/colors.scss';
$color-primary: #f1c400;
$color-secondary: #019cae;

.toggle-button {
  background-color: transparent;
  float: right;
  border: 0;
  cursor: pointer;
  padding-left: 6px;
  line-height: 40px;
}

.play-video-button {
  width: 100%;
}

.ant-modal-content {
  display: flex;
  justify-content: center;

  .ant-modal-body {
    position: relative;
    overflow: hidden;

    .corner-ribbon {
      width: 100px;
      height: 50px;
      top: auto;
      position: absolute;
      background-color: $color-primary;
      color: $color-white;
      text-align: center;
      font-size: 18px;
      right: -35px;
      bottom: -10px;
      left: auto;
      transform: rotate(-45deg);
    }
  }
}

.intro-inner-wrapper {
  max-width: 828px;
  margin: 0 auto;

  .intro-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 21px;
    margin: 20px 0;
  }

  .intro-content {
    padding: 10px 30px;
    font-size: 16px;
    text-align: center;
  }

  .video-responsive-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    margin: 20px 0;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .tailored-report-btn {
    background-color: $color-secondary;
    color: $color-white;
    width: 100%;
  }

  .quick-report-btn {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .ant-menu-item span {
    pointer-events: none;
  }

  .toggle-button {
    float: unset;
  }
}

@media screen and (max-width: 720px) {
  .ant-modal-content {
    .ant-modal-body {
      .corner-ribbon {
        font-size: 15px;
      }
    }

    .intro-title {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 480px) {
  .intro-inner-wrapper {
    .intro-content {
      padding: 0px;
    }
  }
}
