@import 'src/shared/style/colors.scss';

.report-preview-disclaimer {
  position: relative;
  height: 100%;
  background-image: url(../../assets/disclaimer.png);
  background-size: contain;
  background-repeat: no-repeat;

  .report-preview-disclaimer-content {
    position: absolute;
    bottom: 0;
    padding: 40px;
    margin: 72px;
    color: $color-white;

    h2 {
      margin-bottom: 20px;
      color: $color-white;
      font-weight: 700;
    }
  }
}
