@import 'src/shared/style/colors.scss';

.cascader {
  display: flex;

  .cascader-level {
    &.active {
      // flex: none;
    }

    .cascader-title {
      padding: 30px 20px;
      color: currentColor;
      text-align: center;
      text-transform: uppercase;
    }

    .cascader-menu {
      margin: 0;
      padding: 0;
      list-style: none;
      border-top: 1px solid $color-border;

      &-item {
        border-bottom: 1px solid $color-border;
        transition: background-color 0.2s linear;
        cursor: pointer;

        &-inner {
          padding: 20px;
          display: flex;
          align-items: center;

          span {
            margin-right: 10px;
            flex: 1;
          }
        }

        &-icon {
          color: inherit;
          background-color: transparent;
        }

        &:hover,
        &.active {
          background-color: $color-menu-active;
        }
      }
    }

    &:first-child {
      @include themify($themes) {
        color: $color-white;
        background-color: themed('colorSecondary');

        .cascader-menu {
          border-top: 1px solid themed('colorSecondaryLight');

          &-item {
            border-bottom: 1px solid themed('colorSecondaryLight');
          }
        }
      }
    }

    &:nth-child(2) {
      background-color: $color-grey-1;
    }
  }
}
