.tailor-main {
  padding: 40px;

  h3 {
    margin-bottom: 24px;
  }

  .tailor-description {
    margin-bottom: 32px;
  }

  .drop-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .hazard-index-group-item-list.is-dragging-over {
    background-color: #b2bdc7;
  }

  .hazard-index-group-item-placeholder {
    width: 100%;
    height: 220px;
    margin-bottom: 8px;
    border: 1px dashed #019cae;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #a4a4a4;
  }
}
