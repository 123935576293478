.account-setting {
  &-colors {
    display: flex;
    flex-wrap: wrap;
  }

  &-select {
    width: 100%;
    max-width: 400px;
  }
}

@media screen and (max-width: 480px) {
  .account-setting {
    &-select {
      max-width: 100%;
    }
  }
}
