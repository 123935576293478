.error-boundary {
  padding: 40px;

  &-icon {
    width: 400px;
    height: 400px;
    margin: auto;
    background-image: url(assets/oops.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}
