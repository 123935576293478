@import 'src/shared/style/colors.scss';

.course-card {
  margin-bottom: 40px;
  display: flex;

  .course-card-cover {
    width: 48%;
    background-color: $color-grey;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .course-card-info {
    width: 52%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > hr {
      width: 100px;
      height: 2px;
      margin: 20px 0;
      border: none;
      @include themify($themes) {
        background-color: themed('colorSecondary');
      }
    }
  }

  .course-card-description {
    width: 100%;
    max-height: 140px;
    overflow-y: auto;
  }

  .course-card-extra {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .course-card-extra {
      flex-direction: row-reverse;
    }
  }
}

@media screen and (max-width: 480px) {
  .course-card {
    .course-card-cover {
      display: none;
    }

    .course-card-info {
      width: 100%;
      padding: 20px;
    }

    &:nth-child(even) {
      .course-card-extra {
        align-self: flex-end;
      }
    }
  }
}
