@import 'src/shared/style/colors.scss';

.ant-menu.main-menu {
  padding: 10px 40px;
  @include themify($themes) {
    background-color: themed('colorPrimary');

    .ant-menu-item,
    .ant-menu-submenu {
      font-size: 16px;
      font-weight: 500;
      border: none;
      color: themed('colorTitle');

      &:hover,
      &:active,
      &-selected {
        background-color: $color-menu-active;
        border: none;
        border-radius: 4px;
        color: themed('colorTitle');
      }

      &-title {
        &:hover {
          color: themed('colorTitle');
        }
      }

      &.tyk {
        background-color: rgba(0, 0, 0, 0.08);
        margin: 0 5px;

        .highlight-tag {
          position: absolute;
          top: -6px;
          right: -15px;
          padding: 0px 10px;
          line-height: 15px;
          border-radius: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-menu.main-menu {
    padding: 10px 20px;
  }
}
