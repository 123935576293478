@import 'src/shared/style/colors.scss';

.building-cost-calculator-contact {
  padding: 40px;
  text-align: center;

  h4,
  img,
  p {
    margin-bottom: 40px;
  }

  h4 {
    color: $color-white;
    line-height: 1.4;
  }

  p {
    color: $color-white;
    font-size: 12px;
  }
}
