.recent-activities {
  max-height: inherit;

  &.ant-tabs {
    overflow: hidden;

    .ant-tabs-tab {
      color: rgba(0, 0, 0, 0.25);
      font-size: 20px;
      font-weight: 500;
      padding: 20px 40px;
      margin: 0;

      &.ant-tabs-tab-active {
        color: #212223;
      }
    }
  }

  .ant-tabs-tabpane {
    max-height: 360px;
    overflow: hidden;
  }

  &-list-container {
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    max-height: inherit;
  }

  &-list {
    overflow-y: auto;
    transition: box-shadow 0.2s ease-out;

    &.scrolled {
      box-shadow: inset -2px 2px 6px -2px rgba(0, 0, 0, 0.15);
    }

    .ant-list-item {
      padding-right: 20px;

      button {
        text-align: left;
        white-space: normal;
      }
    }
  }
}
