@import 'src/shared/style/colors.scss';

.marsh-report-preview-cover {
  background-image: url(./assets/connecting.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

  &-header {
    padding: 100px 0 120px 75px;
    .company-logo {
      width: 180px;
      height: 100px;
      background-image: url(./assets/marsh-logo.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &-center {
    width: 670px;
    height: 410px;
    padding: 75px;
    background-color: #002c77;
    color: $color-white;

    .marsh-report-title {
      color: $color-white;
    }

    h1 {
      text-transform: uppercase;
      font-size: 40px;
    }

    h2 {
      text-transform: capitalize;
      font-size: 26px;
    }

    h2.insured-business-name {
      text-transform: uppercase;
      color: #009de0;
    }

    .date-issued {
      margin-top: 60px;
      text-transform: capitalize;
      font-size: 16px;
    }
  }

  &-footer {
    margin-top: 260px;
    margin-left: 40px;
    padding-bottom: 62px; // Cover place holder

    .powered-by-container {
      padding: 10px;
      height: 70px;
      width: 220px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}
