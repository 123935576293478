@import 'src/shared/style/colors.scss';

$color-home-primary: #f1c400;
$color-home-secondary: #019cae;
$color-home-secondary-light: #2faebd;
$color-home-secondary-dark: #009299;
$color-home-title: #2d2929;
$color-home-grey: #ececec;

.home {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 1.2;
  }

  h4 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
  }

  h5 {
    position: relative;
    margin-bottom: 40px;
    padding-left: 75px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      height: 2px;
      width: 60px;
      background-color: $color-home-primary;
    }
  }

  p {
    font-size: 16px;
  }

  .home-banner {
    position: relative;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-image: url(assets/banner.jpg);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;

    &-graphic {
      position: absolute;
      top: 50%;
      right: 6%;
      width: 65%;
      transform: translateY(-50%);
      animation: fade-in 4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

      @media all and (-ms-high-contrast: none) {
        height: 65%;
      }
    }

    &-inner {
      width: 60%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-text {
      margin-bottom: 60px;
      z-index: 1;

      h1 {
        position: absolute;
        top: 12%;
        color: $color-home-title;
        font-size: 300px;
        font-weight: 900;
        opacity: 0.1;
        animation: tracking-in 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
      }

      h2 {
        margin-bottom: 20px;
        color: $color-home-title;
        font-size: 90px;
        font-weight: 700;
        line-height: 1.4;
      }

      h3 {
        color: $color-home-title;
        font-size: 28px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        line-height: 1.4;
      }
    }

    &-btn {
      border-radius: 8px;
      height: auto;
      padding: 24px 32px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1;
      color: $color-home-secondary;
      border-color: $color-home-secondary;
      z-index: 10;

      &.primary {
        color: $color-white;
        background-color: $color-home-secondary;

        &:hover {
          color: $color-white;
          background-color: $color-home-secondary-light;
        }
      }

      &:hover {
        color: $color-home-secondary-light;
        border-color: $color-home-secondary-light;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .home-features {
    padding: 120px 10% 80px;
    background-color: $color-white;

    h3 {
      color: $color-home-title;
    }

    &-carousel {
      .ant-typography {
        font-size: 16px;
      }

      &-item {
        padding: 0 20px;
        text-align: center;

        > i {
          margin: 0 auto 30px;
          color: $color-home-title;
          background-color: $color-grey-2;
        }
      }

      .slick-dots {
        li {
          button {
            display: inline-block;
            height: 11px;
            width: 11px;
            background: $color-home-secondary;
            border-radius: 50%;
            opacity: 0.3;
            transition: all 0.4s;
            cursor: pointer;
          }

          &.slick-active {
            button {
              height: 14px;
              width: 14px;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .home-statistic {
    padding: 72px 10%;
    background: linear-gradient(0deg, $color-home-secondary 0%, $color-home-secondary-dark 100%);

    &-item {
      text-align: center;

      h1 {
        position: relative;
        color: $color-white;

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          width: 60px;
          height: 1px;
          margin: 0 auto;
          background-color: $color-white;
        }
      }

      .industries {
        span {
          &::after {
            content: '+';
            position: absolute;
            bottom: 5px;
          }
        }
      }

      h4 {
        color: $color-white;
        font-size: 22px;
        font-weight: 500;
        margin-top: 28px;
        margin-bottom: 30px;
      }
    }
  }

  .home-about {
    padding: 100px 10%;

    h3 {
      color: $color-home-title;
    }

    &-parallax {
      opacity: 0;
      transform: translateY(50px);
      will-change: transform;
      transition: all 0.2s ease-in;

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }

      @media all and (-ms-high-contrast: none) {
        opacity: 1;
      }
    }

    img {
      max-width: 100%;
      margin-top: 65px;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        margin-top: 0;
      }
    }

    &-item {
      > i {
        margin: 30px 0;
        background-color: $color-home-primary;
      }
    }
  }
}

.home-tailored-report {
  padding: 100px 10%;
  text-align: center;
  background-color: $color-home-grey;

  &-feature {
    height: 420px;
    width: 96%;
    padding: 20px;
    box-shadow: 5px 8px 8px 5px rgba(221, 232, 235, 0.6);
  }

  h3 {
    margin-bottom: 100px;
  }

  img {
    margin-top: 30px;
  }

  h4 {
    min-height: 50px;
    margin: 30px 0 5px 0;
    font-size: 20px;
    font-weight: 700;
  }

  &-step {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
    margin-top: -80px;
  }
}

.home-dashboard {
  padding: 100px 10%;

  img {
    max-width: 100%;
  }

  &-image {
    padding: 20px 0px 0px 40px;
  }
}

@media screen and (max-width: 1200px) {
  .home {
    .home-banner {
      &-inner {
        width: 70%;
      }
    }

    .home-dashboard {
      &-image {
        padding: 20px 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .home-banner {
      height: 800px;

      &-text {
        h1 {
          top: 16%;
          font-size: 200px;
        }

        h2 {
          font-size: 72px;
        }

        p {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .home {
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 24px;
    }

    .home-banner {
      &-graphic {
        left: 0;
        width: 100%;
      }

      &-inner {
        width: 100%;
        padding: 0 20px;
        text-align: center;
      }

      &-text {
        margin-bottom: 40px;

        h1 {
          position: relative;
          top: 0;
          font-size: 100px;
        }

        h2 {
          font-size: 56px;
        }
      }

      &-btn {
        padding: 10px 30px;

        &:not(:first-child) {
          margin: 10px;
        }
      }
    }

    .home-features,
    .home-statistic,
    .home-about,
    .home-tailored-report {
      padding: 60px 40px;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in {
  0% {
    letter-spacing: 1em;
  }

  100% {
    letter-spacing: normal;
  }
}
