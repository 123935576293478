@import 'src/shared/style/colors.scss';
@import 'src/shared/style/themes/blue-yellow.scss';

.hazard-index-group {
  &-header {
    background-color: $color-secondary;
    color: #fff;
    flex-basis: 164px;

    .report-right-sider & {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .hazard-index-group-item-list {
    transition: background-color 0.2s ease;
    position: relative;

    > li {
      list-style-type: none;
    }

    &.is-dragging-over {
      background-color: #b2bdc7;
    }

    .hazard-index-group-placeholder {
      position: absolute;
      border: 1px dashed $color-secondary;
      color: rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 500;
      padding: 8px;
    }
  }

  &.report-right-sider {
    padding-left: 50px;

    .hazard-index-group-title {
      margin: 16px 0 8px 0;
      font-weight: 500;
    }

    .hazard-index-group-item-list {
      padding: 8px 16px;
      border: 1px solid $color-grey;
    }

    .hazard-index-group-placeholder {
      border-radius: 2px;
      font-size: 14px;
      margin: 8px auto;

      &.empty {
        position: relative;
        margin: 0;
      }
    }
  }

  &.tailor {
    border: 8px solid $color-secondary;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    width: 32%;

    .hazard-index-group-title {
      font-size: 16px;
      font-weight: 500;
      padding: 16px;
    }

    .hazard-index-group-description {
      padding: 0 16px 24px;
    }

    .hazard-index-group-item-list {
      flex-grow: 1;
      min-height: 200px;
      padding: 16px;
    }

    .hazard-index-group-placeholder {
      border-radius: 4px;

      &.empty {
        top: 16px;
        left: 16px;
        height: 219px;
        width: calc(100% - 32px);
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .hazard-index-group-header {
    flex-basis: 230px;
  }
}

@media screen and (max-width: 1200px) {
  .hazard-index-group-header {
    flex-basis: 272px;
  }
}

@media screen and (max-width: 800px) {
  .hazard-index-group-header {
    flex-basis: 340px;
  }
}
