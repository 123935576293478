@import 'src/shared/style/colors.scss';

.certificate {
  font-size: 16px;

  .page-logo {
    position: absolute;
    top: 60px;
    right: 72px;
    display: flex;
  }

  .yellow-bar {
    position: absolute;
    left: 30px;
    bottom: 0;
    width: 8px;
    height: 87%;
    @include themify($themes) {
      background-color: themed('colorPrimary');
    }
  }

  .black-bar {
    position: absolute;
    left: 39px;
    bottom: 0;
    width: 4px;
    height: 82%;
    @include themify($themes) {
      background-image: linear-gradient(themed('colorGradient1'), themed('colorGradient2'));
    }
  }

  .page-content {
    padding: 220px 72px 0 120px;

    h1,
    h2,
    h3,
    h4 {
      letter-spacing: 0.5px;
    }

    h1 {
      font-size: 44px;
      font-weight: 700;
    }

    h2 {
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: 600;
      text-transform: uppercase;
      @include themify($themes) {
        color: themed('colorSecondary');
      }
    }

    h3 {
      margin-bottom: 40px;
      color: $color-grey-4;
      font-size: 32px;
    }

    h4 {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
    }

    em {
      font-weight: 500;
    }

    .certificate-points {
      display: table;
      text-align: center;
    }

    .certificate-logo {
      height: 60px;
      background-size: contain;
      background-position: 0 center;
      background-repeat: no-repeat;

      &.cip {
        background-image: url(../../assets/logo-cip.png);
      }

      &.cpd {
        background-image: url(../../assets/logo-niba-color.png);
      }
    }

    .certificate-signature {
      position: absolute;
      right: 72px;
      width: 200px;
      text-align: center;

      > img {
        width: 150px;
      }

      > hr {
        height: 2px;
        background-color: $color-grey-4;
      }
    }

    .ant-table-content {
      th,
      td {
        padding: 8px 16px;
        font-size: 16px;
      }
    }
  }
}
