@import 'src/shared/style/colors.scss';

.point-badge {
  margin-right: 20px;
  display: flex;

  .point-badge-logo {
    position: relative;
    padding-right: 15px;
    margin-right: 10px;
    text-align: center;

    &.back {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotateY(180deg);
    }

    img {
      height: 36px;
      display: block;
    }
  }

  .point-badge-number {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    color: $color-white;
    font-size: 10px;
    line-height: 20px;
    border-radius: 50%;
    @include themify($themes) {
      background-color: themed('colorSecondary');
    }
  }

  &.animation {
    transform-style: preserve-3d;
    animation: rotation 10s infinite linear;

    .point-badge-logo,
    .point-badge-number {
      backface-visibility: hidden;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  5% {
    transform: rotateY(180deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  55% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}
