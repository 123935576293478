$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 5px;
$hamburger-hover-opacity: 1;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;

@import 'src/shared/style/colors.scss';
@import '~hamburgers/_sass/hamburgers/hamburgers';

.hamburger {
  @include themify($themes) {
    .hamburger-inner {
      background-color: themed('colorBase');

      &::before,
      &::after {
        background-color: themed('colorBase');
      }
    }
  }
}
