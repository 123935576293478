@import 'src/shared/style/colors.scss';

.hazard-index-modal {
  .hazard-index-modal-content {
    padding: 50px 20px 25px;
    color: $color-white;
    text-align: center;
    @include themify($themes) {
      background-color: themed('colorCard');
    }

    h3 {
      margin: 12px 0;
      color: currentColor;
      text-transform: uppercase;
    }
  }

  .hazard-index-modal-icon {
    font-size: 30px;
  }

  .hazard-index-modal-summary {
    max-height: 66vh;
    min-height: 200px;
    padding: 40px;
    overflow: auto;

    h3 {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .ant-modal-close {
    color: $color-white;
  }
}

@media screen and (max-width: 480px) {
  .hazard-index-modal {
    top: 0;
    padding: 0;

    .hazard-index-modal-content {
      padding: 20px;
    }

    .hazard-index-modal-summary {
      max-height: calc(100vh - 155px);
    }
  }
}
