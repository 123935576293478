.login-logo {
  margin: 15px 0 30px;
  display: flex;
  justify-content: center;
}

.login-form {
  &-link {
    border: 0;
    padding-top: 0.1px;
    line-height: 30px;
  }
}
