@import 'src/shared/style/colors.scss';

.text-editor {
  .EditorToolbar__root___3_Aqz,
  .public-DraftEditorPlaceholder-root {
    line-height: 1.5;
  }

  .public-DraftEditorPlaceholder-root,
  .public-DraftEditorPlaceholder-hasFocus {
    font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    font-feature-settings: 'tnum', 'tnum';
    font-size: 14px;
    color: #bfbfbf;
  }

  .DraftEditor-editorContainer {
    min-height: 160px;
    font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    font-feature-settings: 'tnum', 'tnum';
    line-height: 1.5;
    font-size: 14px;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-ol,
    .RichTextEditor__block___2Vs_D.RichTextEditor__paragraph___3NTf9 {
      margin: 0;
    }
  }
}
