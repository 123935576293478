@import 'src/shared/style/colors.scss';

.gallery {
  .gallery-list {
    position: relative;
    margin: auto;
    padding: 0;
    list-style: none;

    .gallery-item {
      position: absolute;

      > a {
        position: absolute;
        z-index: 1;
      }

      &-info {
        position: absolute;
        padding: 40px;
        display: flex;
        flex-direction: column;
        background: $color-white;

        &-close {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
        }

        > hr {
          width: 100px;
          height: 2px;
          margin: 20px 0;
          border: none;
          @include themify($themes) {
            background-color: themed('colorSecondary');
          }
        }

        &-btns {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
