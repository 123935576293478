@import 'src/shared/style/colors.scss';

.account-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .account-item-label {
    min-width: 100px;
    margin-right: 20px;
    flex: 0;
    font-weight: 700;
    @include themify($themes) {
      color: themed('colorTitle');
    }
  }

  .account-item-control {
    flex: 1;
  }

  &.large {
    margin-bottom: 40px;
  }

  &.vertical {
    display: block;

    .account-item-label {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 480px) {
  .account-item {
    display: block;

    .account-item-label {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
