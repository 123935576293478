@import 'src/shared/style/colors.scss';

.calculator {
  &-item {
    margin-bottom: 20px;
  }

  &-form {
    padding: 40px;
    @include themify($themes) {
      background-color: rgba(themed('colorSecondary'), 0.1) !important;
    }

    &-title {
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    &-section {
      margin-bottom: 40px;

      .ant-form-item {
        &-label {
          label {
            @include themify($themes) {
              color: themed('colorTitle');
            }
          }
        }
      }
    }

    &-label {
      min-height: 50px;
      padding: 10px 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      color: $color-white;
      @include themify($themes) {
        background-color: themed('colorSecondary');
      }

      > i {
        margin-right: 10px;
      }
    }

    &-result {
      background-color: $color-grey-2;
      font-size: 16px;
      @include themify($themes) {
        color: themed('colorTitle');
      }
    }

    &-btn {
      margin-bottom: 20px;
    }
  }
}

.calculator-printing {
  width: fit-content;
  min-width: 100%;
  padding: 32px 16px;
  background-color: $color-bg-printing;

  > section {
    position: relative;
    width: 840px;
    height: 1188px;
    margin: 0 auto 32px;
    padding: 72px;
    background-color: $color-white;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);

    h2,
    h3 {
      margin-bottom: 1em;
    }

    .contact-card {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $color-grey-2;

      img {
        width: 80px;
        margin-right: 10px;
      }

      a {
        @include themify($themes) {
          color: themed('colorSecondary');
        }
      }
    }
  }

  &-form {
    margin-bottom: 20px;

    &-title {
      padding: 16px 40px;
      color: $color-white;
      font-weight: 700;
      text-transform: uppercase;
      @include themify($themes) {
        background-color: themed('colorSecondary');
      }
    }

    &-content {
      padding: 16px 40px;
      background-color: $color-grey-2;
    }

    &-item {
      padding: 9px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      background-color: $color-white;
      @include themify($themes) {
        color: themed('colorTitle');
      }

      &.result {
        background-color: $color-grey;
      }
    }
  }
}

@media print {
  .calculator-printing {
    margin: 0;
    padding: 0;
    background-color: transparent;

    > section {
      margin: 0;
      page-break-after: always;
    }
  }
}

@media screen and (max-width: 480px) {
  .calculator {
    &-form {
      padding: 40px 20px;

      &-section {
        margin-bottom: 20px;
      }
    }
  }
}
