.hazard-index-chart {
  height: 54vw;
  min-height: 500px;
  max-height: 800px;
}

@media screen and (max-width: 1200px) {
  .hazard-index-chart {
    height: 100%;
  }
}
