@import 'src/shared/style/colors.scss';

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  margin: 0;
  @include themify($themes) {
    color: themed('colorTitle');
  }
}

a {
  @include themify($themes) {
    &:hover {
      color: themed('colorPrimary');
    }

    &:active {
      color: themed('colorPrimaryActive');
    }

    &:focus {
      color: themed('colorPrimaryHover');
    }
  }
}

.component-wrapper {
  padding: 40px;
}

.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card {
  background-color: $color-white;
  box-shadow: $color-box-shadow;
}

.responsive-video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  .responsive-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: $color-bg-printing;
}

::-webkit-scrollbar-thumb {
  @include themify($themes) {
    background-color: themed('colorSecondary');
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('colorSecondaryHover');
    }
  }
}

// override antd styles
.ant-spin {
  display: flex;
  justify-content: center;

  .ant-spin-dot-item {
    @include themify($themes) {
      background-color: themed('colorSecondary');
    }
  }
}

.ant-btn-link {
  padding: 0;

  @include themify($themes) {
    &:hover,
    &:focus {
      color: themed('colorPrimaryHover');
    }

    &:active {
      color: themed('colorPrimaryActive');
    }
  }
}

.ant-tabs {
  overflow: visible;

  @include themify($themes) {
    .ant-tabs-tab {
      &-active {
        color: themed('colorPrimary');
      }

      &:hover {
        color: themed('colorPrimaryHover');
      }
    }

    .ant-tabs-ink-bar {
      background-color: themed('colorPrimary');
    }
  }
}

.ant-checkbox-group {
  display: block;
}

.ant-avatar {
  background-color: $color-grey;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    @media all and (-ms-high-contrast: none) {
      height: auto;
    }
  }
}

.ant-menu {
  border: none;
  @include themify($themes) {
    .ant-menu-item {
      color: themed('colorSiderMenu');

      > a {
        color: currentColor;
      }

      &::after {
        border-color: transparent;
      }

      &:hover {
        color: themed('colorSiderMenuHover');
      }

      &:active,
      &-selected {
        color: themed('colorSiderMenu');
        background-color: themed('colorSiderMenuSelected');

        &::after {
          border-color: themed('colorSiderMenu');
        }
      }
    }

    .ant-menu-submenu {
      color: themed('colorSiderMenu');

      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: currentColor;
        }
      }

      &-selected {
        color: themed('colorSiderMenuHover');
      }

      &-title {
        &:hover {
          color: themed('colorSiderMenuHover');
        }
      }
    }
  }
}

.ant-input-prefix {
  color: rgba(0, 0, 0, 0.25) !important;
}

// end of override antd styles

@media screen and (max-width: 480px) {
  .component-wrapper {
    padding: 40px 20px;
  }
}
