@import 'src/shared/style/colors.scss';

.dashboard-feedback {
  padding: 40px;

  h2 {
    margin-bottom: 24px;
    text-align: center;
    line-height: 1.2;
  }

  &-icon {
    margin: 20px auto;
    display: block;
    font-size: 70px;
    @include themify($themes) {
      color: themed('colorSecondary');
    }
  }

  &-intro {
    max-height: 180px;
    margin-bottom: 10px;
    overflow-y: auto;
  }

  &-btn {
    margin-left: auto;
    display: block;
  }
}
