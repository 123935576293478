@import 'src/shared/style/colors.scss';

.hazard-index-classification {
  height: 100%;
  padding: 40px;

  ul {
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    list-style: none;
    @include themify($themes) {
      color: themed('colorTitle');
    }

    > li {
      display: flex;
      align-items: center;
      line-height: 2;

      > i {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }

      > span {
        flex: 1;
      }
    }
  }
}
