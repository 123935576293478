.ant-modal-body {
  width: 100%;
}

.underwirter-edit {
  .dots-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .dot {
      height: 10px;
      width: 10px;
      margin-right: 15px;
      background-color: #9ab8d1;
      border-radius: 50%;

      &.selected {
        background-color: #019cae;
      }
    }
  }
}
