@import 'src/shared/style/colors.scss';

.tyk-browse {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  text-align: center;

  .tyk-industry-search {
    h2,
    .instruction {
      color: $color-secondary;
    }
  }

  .tyk-description {
    margin: 0 auto;
    max-width: 800px;
  }

  .tyk-steps {
    margin-top: 60px;
    img {
      margin-top: 30px;
    }

    h4 {
      min-height: 50px;
      margin: 30px 0 5px 0;
      font-size: 20px;
      font-weight: 700;
    }

    &-step {
      height: 420px;
      width: 96%;
      padding: 10px;
      box-shadow: 5px 8px 8px 5px rgba(221, 232, 235, 0.6);

      .arrow-right {
        background-color: $color-white;
      }

      &-sequence {
        width: 100px;
        padding: 5px;
        text-align: center;
        margin: 0 auto;
        color: $color-white;
        font-size: 16px;
        font-weight: 600;
        background-color: colorPalette($color-grey, 6);
        border-radius: 20px;
        margin-top: -50px;
      }

      &-image {
        max-width: 100%;
        height: 120px;
      }

      &.arrow-next {
        width: 80%;
        ::after {
          width: 40px;
          height: 40px;
          background-image: url(./assets/arrow.svg);
          position: absolute;
          top: 200px;
          right: -60px;
        }
      }
    }
  }

  .tyk-industry-request {
    margin-top: 40px;
    width: 99%;

    &-card {
      box-shadow: 5px 8px 8px 5px rgba(221, 232, 235, 0.6);
      .request-new-industry-knowledge {
        text-align: left;
      }
    }
  }
}
