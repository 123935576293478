@import 'src/shared/style/colors.scss';

.building-cost-calculator-table {
  width: 100%;

  thead {
    color: $color-white;
    text-transform: uppercase;
    @include themify($themes) {
      background-color: themed('colorTableHeader');
    }
  }

  tbody {
    @include themify($themes) {
      color: themed('colorTitle');
    }

    tr {
      &.highlight {
        background-color: $color-grey-2;
      }
    }
  }

  th,
  td {
    padding: 16px;
    text-align: center;

    h3 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
