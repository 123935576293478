.hazard-index-rating {
  @media print {
    min-height: 787.5px;
    font-size: 14px;
  }
}

.hazard-index-note {
  margin-bottom: 40px;
}

.hazard-index-table {
  margin-bottom: 24px;
}
