@import 'src/shared/style/colors.scss';

.hazard-index-edit {
  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      padding: 0;
    }

    .hazard-index-icon {
      width: 40px;
      height: 40px;
    }

    .info {
      margin-left: 16px;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: 500;

        .name {
          margin-right: 56px;
          font-size: inherit;
          font-weight: inherit;
          text-transform: uppercase;
          color: $color-secondary;
        }
      }

      @media screen and (max-width: 576px) {
        margin-left: 0;
        width: 100%;
        .title {
          width: 100%;
          flex-direction: column;

          .name {
            margin-top: 8px;
            margin-right: 0;
            margin-bottom: 16px;
          }

          a {
            width: 100%;
            button {
              width: 100%;
            }
            span {
              width: 100%;
              word-break: normal;
              width: auto;
              display: block;
              white-space: pre-wrap;
              word-wrap: break-word;
              overflow: hidden;
            }
          }

          .rating {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    padding: 0 56px;

    .sub-section {
      width: 50%;
      &:first-child {
        margin-right: 32px;
        margin-bottom: 24px;
      }

      &.comments {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
      .sub-section {
        width: 100%;
      }
    }

    @media screen and (max-width: 576px) {
      padding: 0;
    }

    .content-heading {
      margin-bottom: 16px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }

    .description {
      padding-right: 16px;
      max-height: 400px;
      overflow-y: auto;
    }

    .add-checklist-button {
      margin-left: 10px;
      background-color: colorPalette($color-secondary, 1);
    }
  }
}
