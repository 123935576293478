@import 'src/shared/style/colors.scss';

.endorsement-list {
  padding: 40px;

  &-item {
    padding: 2rem 0;

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > h4 {
      @include themify($themes) {
        color: themed('colorSecondary');
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .endorsement-list {
    padding: 20px;
  }
}
