@import 'src/shared/style/colors.scss';

.report-preview-back-cover {
  position: relative;
  height: 100%;

  &-info {
    position: absolute;
    bottom: 200px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &-contact {
    width: 60%;
    margin-right: 40px;
    height: 160px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: $color-white;
      text-align: right;
    }
  }
}
