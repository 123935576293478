@import 'src/shared/style/colors.scss';

.hazard-index-mode {
  &-icon {
    opacity: 0.4;
    font-size: 22px;
    cursor: pointer;
    transition: opacity 0.2s;
    @include themify($themes) {
      color: themed('colorBase');
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    &:hover {
      opacity: 0.6;
    }

    &.active {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 480px) {
  .hazard-index-mode {
    display: none;
  }
}
