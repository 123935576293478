.general-info {
  padding: 40px;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.4;
    text-transform: uppercase;
  }

  &-description {
    max-height: 120px;
    overflow-y: auto;
  }

  &-carousel {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .slides {
      width: calc(100% - 84px);
      max-width: 400px;
      margin: 0 10px;
    }

    .slide {
      text-align: center;

      > i {
        margin: 0 auto 10px;
      }

      > h3 {
        font-weight: 700;
        line-height: 1;
      }
    }
  }

  &-btn {
    align-self: center;
  }
}
