.notepad {
  height: 100%;

  &-slider {
    width: 100%;
    height: 100%;
    display: flex;

    &-slide {
      overflow-y: auto;

      &.left {
        width: 25%;
      }

      &.right {
        width: 75%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .notepad {
    &-slider {
      &-slide {
        &.left {
          width: 35%;
        }

        &.right {
          width: 65%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .notepad {
    width: 100%;
    max-height: 500px;
    overflow: hidden;

    &-slider {
      width: 200%;
      display: flex;
      will-change: transform;
      transition: transform 0.2s ease-out;

      &.left {
        transform: translateX(0);
      }

      &.right {
        transform: translateX(-50%);
      }

      &-slide {
        &.left,
        &.right {
          width: 50%;
        }
      }
    }
  }
}
