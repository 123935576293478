@import 'src/shared/style/colors.scss';

.account-dropdown {
  max-width: 240px;

  .account-dropdown-body {
    padding: 20px 30px 10px;
    text-align: center;

    &-name {
      margin-top: 20px;
      margin-bottom: 5px;
      line-height: 1.2;
      text-transform: uppercase;
    }

    &-links {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      @include themify($themes) {
        color: themed('colorSecondary');
      }
    }
  }

  .account-dropdown-footer {
    background-color: $color-grey-1;
  }
}
