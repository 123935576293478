.layout {
  min-height: 100vh;

  .content {
    margin-top: 78px;

    &.home {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .layout {
    .content {
      margin-top: 64px;
    }
  }
}
