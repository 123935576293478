@import 'src/shared/style/colors.scss';

.hazard-index-detail {
  &-item {
    padding: 2rem 0;

    .ant-list-item-extra {
      line-height: 1;
    }

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
  }

  &-icon {
    margin: 0 20px;
    svg {
      filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
    }
  }

  &-title {
    height: auto;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    white-space: normal;
  }

  &-rating {
    font-size: 36px;
    font-weight: 400;
    margin-right: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }

  &-comment {
    padding-left: 104px;

    ul,
    ol {
      margin-bottom: 1em;
    }
  }
}
