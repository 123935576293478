.dashboard {
  &-greet {
    margin-bottom: 40px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-item {
    max-height: 480px;
    margin-bottom: 20px;

    > * {
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    &-greet {
      text-align: center;
      flex-direction: column;

      h1 {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
  }
}
