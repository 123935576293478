.account-card-body {
  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > .account-avatar,
    .create-button {
      margin-right: 40px;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;

      > .account-avatar,
      .create-button {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
}
