@import './utils/colorPalette.scss';
@import './utils/themify.scss';
@import './themes/themes.scss';

$color-white: #ffffff;
$color-black: #000000;
$color-grey: #b2bdc7;
$color-grey-1: colorPalette($color-grey, 1);
$color-grey-2: colorPalette($color-grey, 2);
$color-grey-3: colorPalette($color-grey, 8);
$color-grey-4: colorPalette($color-grey, 9);
$color-border: $color-grey-2;
$color-bg-document: $color-grey-1;
$color-bg-printing: colorPalette($color-white, 7.5);
$color-menu-active: rgba(0, 0, 0, 0.2);
$color-shadow: rgba(0, 0, 0, 0.4);
$color-mask: rgba(0, 0, 0, 0.65);
$color-success: #52c41a;
$color-fail: #f5222d;

$color-box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
$color-box-shadow-light: 0 1px 1px 0 rgba(60, 64, 67, 0.08);
