@import 'src/shared/style/colors.scss';

$header-height-normal: 78px;
$header-height-compact: 64px;

.header {
  position: fixed;
  width: 100%;
  min-height: $header-height-normal;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  z-index: 100;
  transform: translateY(-100%);
  will-change: transform;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.visible {
    transform: translateX(0);
  }

  &.shadow {
    box-shadow: $color-box-shadow;
  }

  .header-brand {
    height: 48px;
    @include themify($themes) {
      color: themed('colorHeader');
    }

    @media all and (-ms-high-contrast: none) {
      width: 161px;
    }
  }

  &.transparent {
    background: transparent;
    box-shadow: none;

    .header-brand {
      color: $color-white;
    }
  }

  .header-badge {
    margin-left: auto;
  }

  .header-user {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .header-avatar {
      @include themify($themes) {
        background-color: themed('colorHeader');
      }
    }

    .header-name {
      margin: 0 15px;
      color: $color-grey-3;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    .header-icon {
      color: $color-grey-4;
      font-size: 28px;
    }
  }

  .header-login-btn {
    color: $color-grey-4;
    border-color: $color-grey-4;

    &:hover {
      color: $color-grey-3;
      border-color: $color-grey-3;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    min-height: $header-height-compact;

    .header-brand {
      height: 36px;
      @media all and (-ms-high-contrast: none) {
        width: 120px;
      }
    }

    .header-badge {
      display: none;
    }

    .header-user {
      .header-avatar {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }

      .header-name {
        display: none;
      }

      .header-icon {
        display: none;
      }
    }
  }
}
