@import 'src/shared/style/colors.scss';

.sider {
  padding: 40px 0;
  background-color: $color-white;
  box-shadow: $color-box-shadow;

  .sider-title {
    margin: 0 auto 40px;
    display: table;
  }

  .sider-icon {
    margin-bottom: 20px;
    display: block;
    font-size: 40px;
    @include themify($themes) {
      color: themed('colorSecondary');
    }
  }

  .sider-description {
    padding: 0 40px;
  }

  .ant-menu-item.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .ant-menu-item > a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 992px) {
  .sider {
    display: none;

    &.collapsible {
      display: block;

      .sider-title,
      .sider-icon,
      .sider-description {
        display: none;
      }
    }
  }
}
