@import 'src/shared/style/colors.scss';

.print-article {
  width: fit-content;
  min-width: 100%;
  padding: 32px 16px;
  background-color: $color-bg-printing;

  .print-page {
    position: relative;
    width: 840px;
    height: 1188px;
    margin: 0 auto;
    background-color: $color-white;
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.25);
  }

  .print-dynamic-page {
    position: relative;
    width: 840px;
    margin: 0 auto;
    padding: 100px 0;
    background: $color-white;
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.25);
  }

  .print-paragraph {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 480px) {
  .print-article {
    transform: scale(0.45);
    transform-origin: top left;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .print-article {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  .print-page {
    // width: 21cm;
    // height: 29.7cm;
    margin: 0;
    box-shadow: none;
    page-break-after: always;
  }

  .print-dynamic-page {
    // width: 21cm;
    margin: 0;
    padding: 0;
    box-shadow: none;
    page-break-after: always;
  }

  .print-page-break {
    page-break-after: always;
  }
}
