@import 'src/shared/style/colors.scss';

.drawer {
  position: relative;
  width: 60px;
  padding: 20px;
  flex: none;
  transition: all 0.2s;

  .drawer-toggle-btn {
    position: absolute;
    right: 20px;
  }

  .drawer-content {
    margin-top: 40px;
    display: none;

    &-title {
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;
    }

    &-divider {
      @include themify($themes) {
        background-color: themed('colorBase');
      }
    }
  }

  &.visible {
    width: 440px;

    .drawer-content {
      display: block;
    }
  }
}

@media screen and (max-width: 480px) {
  .drawer {
    &.visible {
      width: 100%;
    }
  }
}
