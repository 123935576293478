.account-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-avatar {
    margin-bottom: 20px;

    &.transparent {
      background-color: transparent;

      img {
        object-fit: contain;
      }
    }
  }
}
