.note-content {
  min-height: 100%;
  padding: 40px;

  &-back {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .note-content {
    padding: 20px;

    &-back {
      display: block;
    }
  }
}
