.hazard-index-item-container.underwriter-list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hazard-index-item {
    flex-grow: 1;
    border: none;
    padding: 10px 20px 10px 30px;
  }
}
