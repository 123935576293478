@import 'src/shared/style/colors.scss';

.under-insurance-calculator-example {
  @include themify($themes) {
    color: themed('colorTitle');
  }

  &-section {
    padding: 12px 20px;

    &:first-child {
      background-color: $color-grey-2;
    }

    &:last-child {
      background-color: $color-grey;
    }
  }

  table {
    text-align: center;

    tr {
      &.blank {
        height: 16px;
      }
    }

    td {
      padding: 0 10px;
      font-size: 14px;
      line-height: 1.2;

      .divider {
        margin: 0;
        border-width: 1px 0 0 0;
        @include themify($themes) {
          border-color: themed('colorTitle');
        }
      }
    }
  }
}
